import React, { useEffect, useState } from "react";
import {
  FileDoneOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Divider, Steps, Table, Tag, Tooltip } from "antd";
import "./SMSDetailsPage.less";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { Loader } from "../Loader/Loader";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import SMSDetailsPageMobile from "../SMSDetailsPageMobile/SMSDetailsPageMobile";
import { capitalize, getColors } from "../../utils/Utils";
import moment from "moment";
import { useQuery } from "../../hooks/useQuery";
import { useSelector } from "react-redux";
import { openModal } from "../../actions/uiActions";
import { ModalType } from "../../models/Modal";
import { Document } from "../../api/sellmyshares-api";
import { useAppDispatch } from "../../hooks/reactRedux";
import { GeneralState } from "../../models/State";
import { setErrorCode } from "../../store/errorCodeSlice";

interface TitleWithToolTipProps {
  title: string;
  label: string;
}

const TitleWithToolTip: React.FC<TitleWithToolTipProps> = ({
  title,
  label,
}) => {
  return (
    <>
      {label}
      <span style={{ borderRadius: 50, height: 2, overflow: "hidden" }}>
        <Tooltip title={title}>
          <Button
            style={{ border: "none", height: 0, color: "#cccccc" }}
            type="default"
            shape="circle"
            size="small"
            icon={<QuestionCircleOutlined />}
          />
        </Tooltip>
      </span>
    </>
  );
};

const SMSDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [sale, setSale] = useState<any>({});
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const query = useQuery();
  const sydneyCurrentTime = new Date(sale.currentTimeSydney);
  const { reload } = useSelector<GeneralState, any>((state) => state.ui);
  const isSaleSpecialStatus =
    sale &&
    sale.status &&
    (sale.status.toUpperCase() === "DUPLICATE" ||
      sale.status.toUpperCase() === "CANCELLED" ||
      sale.status.toUpperCase() === "INT. SHARES" ||
      sale.status.toUpperCase() === "HIN");

  const fetchData = async (saleId: string, contactLastname: string) => {
    try {
      const sales = await api.getSaleStatus(saleId, contactLastname);
      const docs = await api.listDocuments(saleId, contactLastname);
      setSale(sales);
      setDocuments(docs);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      const errorCode = (err as any).response?.status || 500;
      dispatch(setErrorCode(errorCode));
      navigate("/sale-status");
    }
  };

  useEffect(() => {
    const saleId = query.get("saleId") || "";
    const contactLastName = query.get("contactLastName") || "";
    fetchData(saleId, contactLastName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const getParcelsStatus = (record: any) => {
    const isLodgedOrPending =
      record.status &&
      (record.status.toUpperCase() === "LODGED" ||
        record.status.toUpperCase() === "PENDING" ||
        record.status.toUpperCase() === "CANCELLED");

    if (isLodgedOrPending) {
      return record.status; // Return Lodge - Pending - Cancelled
    }

    if (record.saleDate) {
      if (
        moment(record.saleDate).isBefore(new Date(sydneyCurrentTime), "day")
      ) {
        return "✅ Sold"; // Return Sold when sale date is before current date
      }

      if (
        moment(record.saleDate).isSame(new Date(sydneyCurrentTime), "day") &&
        parseInt(sale.currentTimeSydney.split("T")[1].split(":")[0]) < 16
      ) {
        return "Selling Today"; // Return Selling Today when saleDate is current date and Sydney's time before 4 pm
      }

      if (
        moment(record.saleDate).isSame(new Date(sydneyCurrentTime), "day") &&
        parseInt(sale.currentTimeSydney.split("T")[1].split(":")[0]) >= 16
      ) {
        return "✅ Sold"; // Return Sold when saleDate is current date and Sydney's time after 4 pm
      }

      return "Pending"; // Return Pending when saleDate after current date
    }
    return "Pending"; // Return Pending by default
  };

  const personsColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "Relationship",
      dataIndex: "relationshipType",
      width: 180,
    },
    {
      title: "Terms & Conditions",
      dataIndex: "tncStatus",
      render: (text: boolean, record: any) => {
        return record.underPOA
          ? "N/A"
          : isSaleSpecialStatus
          ? "-"
          : text
          ? "✅ Accepted"
          : "Pending";
      },
    },
    {
      title: "ID Verified",
      dataIndex: "kycStatus",
      render: (text: string) => {
        return isSaleSpecialStatus
          ? "-"
          : text?.includes("PASS")
          ? "✅ Yes"
          : "Pending";
      },
    },
  ];

  const shareParcelsColumns: any = [
    {
      title: "Company / Code",
      dataIndex: "companyCode",
      width: 200,
    },
    {
      title: "Number of Shares",
      dataIndex: "units",
      render: (text: number, record: any) => {
        return text > 1 ? record.units : record.howMuch;
      },
    },
    {
      title: () => (
        <TitleWithToolTip
          title={"Have you provided a Security Reference Number (SRN)?"}
          label={"SRN Supplied"}
        />
      ),
      dataIndex: "srnSupplied",
      render: (text: boolean) => {
        return isSaleSpecialStatus ? "-" : text ? "✅ Yes" : "No";
      },
    },
    {
      title: "Sale Status",
      dataIndex: "saleStatus",
      render: (text: string, record: any) => {
        return isSaleSpecialStatus ? sale.status : getParcelsStatus(record);
      },
    },
  ];

  const documentsColumns: any = [
    {
      title: "Name",
      dataIndex: "filename",
      render: (text: string) => {
        return (
          <span>
            {<FileDoneOutlined />} {text}
          </span>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "documentType",
      render: (text: string) => {
        return <Tag>{capitalize(text.replaceAll("_", " "))}</Tag>;
      },
    },
    {
      title: "Date",
      dataIndex: "creationDate",
      render: (text: string) => {
        return moment(text).format("DD/MM/yyyy");
      },
    },
  ];

  if (isLoading) return <Loader text="Fetching Sales..." />;

  if (width < 500) {
    return <SMSDetailsPageMobile data={sale} documents={documents} />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="SMSDetailsPage">
        {sale.status.toUpperCase() !== "CANCELLED" &&
        sale.status.toUpperCase() !== "DUPLICATE" &&
        sale.status.toUpperCase() !== "INT. SHARES" &&
        sale.status.toUpperCase() !== "HIN" ? (
          <Steps
            current={
              sale.status.toUpperCase() === "NEW"
                ? 0
                : sale.status.toUpperCase() === "SOLD"
                ? 2
                : 1
            }
            items={[
              { title: "New" },
              { title: "Processing" },
              { title: "Sold" },
            ]}
            percent={sale.status.toUpperCase() === "SOLD" ? 100 : 60}
            style={{ color: "#000000" }}
          ></Steps>
        ) : null}
        {sale.status.toUpperCase() !== "CANCELLED" &&
        sale.status.toUpperCase() !== "DUPLICATE" &&
        sale.status.toUpperCase() !== "INT. SHARES" &&
        sale.status.toUpperCase() !== "HIN" ? (
          <Divider />
        ) : null}
        <div className="sale-details-header">
          <img
            style={{ width: 64 }}
            alt="SMS small Logo"
            src={process.env.PUBLIC_URL + "/images/sms-small-logo.png"}
          />
          <h1 className="sms-title">Share Sale Details</h1>
          <div className="sms-tag">
            {sale.status && isSaleSpecialStatus ? (
              <Tag
                color={getColors(sale.status ?? "")}
                style={{
                  color: "#000000",
                  borderRadius: 16,
                  padding: "2px 15px",
                  fontWeight: "bold",
                }}
              >
                {sale.status.toUpperCase()}
              </Tag>
            ) : null}
          </div>
        </div>
        <div className="sale-details-container">
          <div className="individual-info-column">
            <p className="info-title">SALE NO/ID</p>
            <p className="info-description">{sale.saleId}</p>
          </div>
          <div className="individual-info-column">
            <p className="info-title">DATE LODGED</p>
            <p className="info-description">{sale.dateLodged.split("T")[0]}</p>
          </div>
          <div className="individual-info-column">
            <p className="info-title">SHAREHOLDER TYPE</p>
            <p className="info-description">
              {sale.shareholderType
                ? sale.shareholderType.split("(")[0]
                : sale.shareholderType}
            </p>
          </div>
          <div className="individual-info-column">
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p className="info-title">IN TRUST</p>{" "}
              <Tooltip title={"Are the shares held in a trust?"}>
                <Button
                  style={{ border: "none" }}
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<QuestionCircleOutlined />}
                />
              </Tooltip>
            </span>
            <p className="info-description">{sale.isTrust ? "Yes" : "No"}</p>
          </div>
          <div className="individual-info-column">
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p className="info-title">POA</p>{" "}
              <Tooltip
                placement="topLeft"
                title={"Are you acting as Power of Attorney?"}
              >
                <Button
                  style={{ border: "none" }}
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<QuestionCircleOutlined />}
                />
              </Tooltip>
            </span>
            <p className="info-description">
              {sale.isPoa ? <span>Yes</span> : <span>No</span>}
            </p>
          </div>
        </div>

        <h2 style={{ marginTop: "1rem", fontSize: 24, fontWeight: 700 }}>
          Persons
        </h2>
        <Table
          className="info-table"
          rowKey="name"
          columns={personsColumns}
          dataSource={sale.naturalPersons}
          size="middle"
          pagination={false}
        />

        <h2 style={{ marginTop: "1.5rem", fontSize: 24, fontWeight: 700 }}>
          Share Holdings <span style={{ fontWeight: 100 }}>(Parcels)</span>
        </h2>
        <Table
          className="info-table"
          rowKey="companyCode"
          columns={shareParcelsColumns}
          dataSource={sale.shareParcels}
          size="middle"
          pagination={false}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <h2 style={{ marginTop: "1.5rem", fontSize: 24, fontWeight: 700 }}>
            Uploaded Documents
          </h2>
          <Button
            onClick={() => dispatch(openModal(ModalType.fileUploaderModal))}
            type="primary"
            icon={<UploadOutlined />}
            style={{ position: "absolute", right: 0, borderRadius: 0 }}
          >
            Upload
          </Button>
        </div>

        <Table
          className="info-table"
          columns={documentsColumns}
          dataSource={documents.map((item: any, index: number) => ({
            ...item,
            key: index,
          }))}
          size="middle"
          pagination={false}
        />
      </div>
      <img
        style={{ width: 180, margin: "auto" }}
        alt="SMS Logo"
        src={process.env.PUBLIC_URL + "/images/sellmyshares-logo.svg"}
      />
    </div>
  );
};

export default SMSDetailsPage;
