import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ErrorState {
  //   error: string | null;
  errorCode: number | null;
}

const initialState: ErrorState = {
  //   error: null,
  errorCode: null,
};

const errorCodeSlice = createSlice({
  name: "errorCode",
  initialState,
  reducers: {
    setErrorCode: (state, action: PayloadAction<number | null>) => {
      //   state.error = action.payload;
      state.errorCode = action.payload;
    },
    clearErrorCode: (state) => {
      state.errorCode = null;
    },
  },
});

export const { setErrorCode, clearErrorCode } = errorCodeSlice.actions;
export default errorCodeSlice.reducer;
